import React from 'react';
import { Router } from '@reach/router';

import Job from '../../../views/Jobs/pages/show';
import NotFound from '../../404';
import { withPrefix } from 'gatsby';

const HourlyGig = () => {
  // TODO: Find a proper way to prevent 404 page on direct load and missing styling.
  if (typeof window === 'undefined') return null;
  return (
    <Router basepath={withPrefix('/jobs/hourly-gig')}>
      <Job path="/:jobId" />
      <NotFound path="/" />
    </Router>
  );
};

export default HourlyGig;
